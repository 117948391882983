import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import './styling/AboutPageStyling.css';
import teamImage from '../assets/team.png';
import matrixPill from '../assets/matrix-pill.png';
import inception from '../assets/inception.jpg';

const AboutPage = () => {
  return (
    <div className="about-container">
      <Navbar />
      <main className="about-main">
        <section className="about-hero">
          <div className="about-hero-content">
            <span className="hero-subtitle">WELCOME TO DEJA VU</span>
            <h1>Unlock Your Mind's True Potential</h1>
            <p className="hero-description">
              Empowering individuals to achieve extraordinary memory abilities through 
              scientifically-proven techniques and cutting-edge cognitive training.
            </p>
            <div className="hero-stats">
              <div className="stat-item">
                <span className="stat-number">50K+</span>
                <span className="stat-label">Active Users</span>
              </div>
              <div className="stat-divider"></div>
              <div className="stat-item">
                <span className="stat-number">89%</span>
                <span className="stat-label">Success Rate</span>
              </div>
              <div className="stat-divider"></div>
              <div className="stat-item">
                <span className="stat-number">200+</span>
                <span className="stat-label">Memory Exercises</span>
              </div>
            </div>
          </div>
        </section>

        <section className="about-vision">
          <div className="vision-content">
            <div className="vision-text">
              <span className="section-subtitle">OUR VISION</span>
              <h2>Revolutionizing Cognitive Enhancement</h2>
              <p>
                Founded in 2023, Deja Vu emerged from a simple yet powerful idea: 
                democratizing extraordinary memory abilities. We combine neuroscience, 
                technology, and personalized learning to help you unlock your cognitive potential.
              </p>
              <ul className="vision-features">
                <li>
                  <span className="feature-icon">🎯</span>
                  <div className="feature-text">
                    <h4>Precision Training</h4>
                    <p>Personalized programs adapted to your learning style</p>
                  </div>
                </li>
                <li>
                  <span className="feature-icon">🧬</span>
                  <div className="feature-text">
                    <h4>Scientific Approach</h4>
                    <p>Based on latest neuroscience research</p>
                  </div>
                </li>
                <li>
                  <span className="feature-icon">📈</span>
                  <div className="feature-text">
                    <h4>Measurable Results</h4>
                    <p>Track your progress with detailed analytics</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="vision-image">
              <img src={teamImage} alt="Our Vision" />
            </div>
          </div>
        </section>

        <section className="about-methodology">
          <div className="methodology-content">
            <span className="section-subtitle">OUR METHODOLOGY</span>
            <h2>The Science of Memory Enhancement</h2>
            <div className="methodology-grid">
              <div className="methodology-card">
                <span className="methodology-icon">🧠</span>
                <h3>Neuroplasticity</h3>
                <p>Scientifically proven to enhance brain connectivity and cognitive function</p>
              </div>
              <div className="methodology-card">
                <span className="methodology-icon">⚡</span>
                <h3>Rapid Learning</h3>
                <p>Master new skills and information up to 3x faster than traditional methods</p>
              </div>
              <div className="methodology-card">
                <span className="methodology-icon">🎯</span>
                <h3>Precision Recall</h3>
                <p>Access memories with unprecedented accuracy and detail</p>
              </div>
              <div className="methodology-card">
                <span className="methodology-icon">🔄</span>
                <h3>Adaptive Training</h3>
                <p>Personalized programs that evolve with your progress</p>
              </div>
            </div>
          </div>
        </section>

        <section className="about-cta">
          <div className="cta-content">
            <h2>Ready to Transform Your Memory?</h2>
            <p>Join thousands of others who have already unlocked their cognitive potential</p>
            <div className="cta-buttons">
              <button className="cta-button primary">Start Free Trial</button>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default AboutPage;
