import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import './styling/ContactPageStyling.css';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically handle the form submission to your backend
    setSubmitted(true);
    setTimeout(() => setSubmitted(false), 3000);
    setFormData({ name: '', email: '', subject: '', message: '' });
  };

  return (
    <div className="contact-container">
      <Navbar />
      <main className="contact-main">
        <div className="contact-hero">
          <h1>Get in Touch</h1>
          <p>Have questions? We'd love to hear from you.</p>
        </div>
        
        <div className="contact-content">
          <div className="contact-info">
            <div className="info-card">
              <span className="info-icon">📍</span>
              <h3>Our Location</h3>
              <p>525 Market Street<br />San Francisco, CA 94105</p>
            </div>
            <div className="info-card">
              <span className="info-icon">📞</span>
              <h3>Phone</h3>
              <p>+1 (415) 832-9467<br />Mon-Fri 9am-6pm PST</p>
            </div>
            <div className="info-card">
              <span className="info-icon">✉️</span>
              <h3>Email</h3>
              <p>support@dejavuapp.com<br />info@dejavuapp.com</p>
            </div>
          </div>

          <div className="contact-form-container">
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Your Email"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  placeholder="Subject"
                  required
                />
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Your Message"
                  required
                  rows="6"
                ></textarea>
              </div>
              <button type="submit" className="submit-button">
                Send Message
              </button>
            </form>
          </div>
        </div>
        
        {submitted && (
          <div className="success-message">
            <p>✅ Thank you for your message! We'll get back to you soon.</p>
          </div>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default ContactPage;
