import React from 'react';
import PaymentForm from './PaymentForm';
import './componentStyling/TrialPopupStyling.css';

const TrialPopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>×</button>
        <h2>Start Your Free Trial Today!</h2>
        <div className="trial-details">
          <p className="trial-highlight">🎉 3 Days Free Trial</p>
          <p className="then-text">Then $60/year</p>
          <div className="price-details">
            <span className="original-price">$129.99</span>
            <span className="discount-price">$60</span>
            <span className="savings-tag">Save 54%</span>
          </div>
        </div>
        <PaymentForm onClose={onClose} />
        <p className="terms">Cancel anytime during trial period.</p>
      </div>
    </div>
  );
};

export default TrialPopup; 