import React from 'react';

const PaymentForm = ({ onClose }) => {
  const handleCheckout = () => {
    console.log('Redirecting to Stripe...');
    const successUrl = `${window.location.origin}/success`;
    const cancelUrl = window.location.origin;
    
    // New Stripe Checkout link
    window.location.href = `https://buy.stripe.com/cN2dTd1cC6XB3jq289?success_url=${encodeURIComponent(successUrl)}&cancel_url=${encodeURIComponent(cancelUrl)}`;
  };

  return (
    <button 
      onClick={handleCheckout}
      className="start-trial-button"
      style={{ marginTop: '20px' }}
    >
      Start Trial
    </button>
  );
};

export default PaymentForm; 