import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styling/SuccessPageStyling.css';

const SuccessPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Automatically redirect to home after 5 seconds
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="success-container">
      <div className="success-content">
        <h1>🎉 Welcome to Deja Vu Premium!</h1>
        <p>Your subscription has been successfully activated.</p>
        <p>You will be redirected to the homepage in 5 seconds...</p>
        <button 
          onClick={() => navigate('/')}
          className="home-button"
        >
          Go to Homepage
        </button>
      </div>
    </div>
  );
};

export default SuccessPage; 