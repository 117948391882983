// Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './componentStyling/NavbarStyling.css';
import TrialPopup from './TrialPopup';

const Navbar = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-brand">
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <h1><span className="logo-icon">🧠</span> Deja Vu</h1>
          </Link>
        </div>
        <div className="nav-links">
          <Link to="/" className="nav-link">Home</Link>
          <Link to="/about" className="nav-link">About</Link>
          <Link to="/contact" className="nav-link">Contact</Link>
          <button 
            onClick={() => setIsPopupOpen(true)} 
            className="nav-link free-trial-btn"
          >
            Free Trial
          </button>
        </div>
      </nav>
      <TrialPopup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} />
    </>
  );
};

export default Navbar;