// HomePage.js
import React from 'react';
import Navbar from '../components/Navbar';
import './styling/HomePageStyling.css';
import bannerVideo from '../assets/banner-video.mp4';
import Footer from '../components/Footer';

const HomePage = () => {
  return (
    <div className="homepage-container">
      <Navbar />
      <main className="homepage-main-content">
        <section className="homepage-hero-section">
          <video 
            autoPlay 
            muted 
            loop 
            playsInline
            className="banner-video"
          >
            <source src={bannerVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="homepage-hero-content">
            <h1>Enhance Your Memory,<br />Unlock Your Potential</h1>
            <p>Discover scientifically-proven techniques, engaging mini-games, and curated resources to improve your memory and cognitive abilities.</p>
            <button className="homepage-cta-button">Get Started</button>
          </div>
        </section>

        <section className="homepage-promotion-section">
          <div className="promotion-container">
            <div className="promotion-content">
              <div className="promotion-text">
                <span className="limited-offer">Limited Time Offer</span>
                <h2>Transform Your Memory Today</h2>
                <p>Get lifetime access to all premium features</p>
                <div className="pricing">
                  <span className="original-price">$129.99</span>
                  <div className="price-container">
                    <span className="discount-price">$60</span>
                    <span className="tax-note">Tax included</span>
                  </div>
                  <span className="savings">Save 54%</span>
                </div>
                <ul className="promotion-features">
                  <li>✓ Unlimited access to all memory games</li>
                  <li>✓ Personalized training programs</li>
                  <li>✓ Advanced progress analytics</li>
                  <li>✓ Priority customer support</li>
                </ul>
                <button className="promotion-cta-button">
                  Claim Your Discount
                  <span className="timer">23:59:59</span>
                </button>
              </div>
              <div className="promotion-badge">
                <div className="badge-content">
                  <span className="badge-text">BEST</span>
                  <span className="badge-text">VALUE</span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="homepage-features-section">
          <h2>What We Offer</h2>
          <div className="homepage-features-grid">
            <div className="feature-card">
              <div className="feature-icon">🎮</div>
              <h3>Memory Games</h3>
              <p>Engaging mini-games designed to exercise different aspects of your memory.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">📚</div>
              <h3>Curated Books</h3>
              <p>Hand-picked collection of books on memory improvement and cognitive enhancement.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">💡</div>
              <h3>Tips & Tricks</h3>
              <p>Practical techniques and strategies for better memory retention.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">📊</div>
              <h3>Progress Tracking</h3>
              <p>Monitor your improvement with detailed analytics and insights.</p>
            </div>
          </div>
        </section>

        <section className="homepage-benefits-section">
          <div className="homepage-benefits-content">
            <h2>Why Choose Us</h2>
            <div className="homepage-benefits-grid">
              <div className="homepage-benefit-item">
                <span className="homepage-benefit-number">01</span>
                <h3>Science-Backed Methods</h3>
                <p>Our techniques are based on extensive research and proven cognitive science principles.</p>
              </div>
              <div className="homepage-benefit-item">
                <span className="homepage-benefit-number">02</span>
                <h3>Personalized Experience</h3>
                <p>Adaptive learning paths that evolve with your progress and needs.</p>
              </div>
              <div className="homepage-benefit-item">
                <span className="homepage-benefit-number">03</span>
                <h3>Expert Guidance</h3>
                <p>Learn from certified memory coaches and cognitive training specialists.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="homepage-testimonials-section">
          <h2>Success Stories</h2>
          <div className="homepage-testimonials-grid">
            <div className="homepage-testimonial-card">
              <div className="homepage-testimonial-content">
                <p>"The memory games are addictive and effective! I've seen a significant improvement in my ability to remember names and faces at networking events. Worth every penny for my professional development."</p>
                <div className="homepage-testimonial-author">
                  <img src={require('../assets/review1.jpg')} alt="James L." className="homepage-testimonial-avatar" />
                  <div>
                    <h4>James L.</h4>
                    <p>Sales Executive</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="homepage-testimonial-card">
              <div className="homepage-testimonial-content">
                <p>"As a medical student, memorizing complex terminology and procedures was always challenging. After using Deja Vu for 3 months, my retention rate has improved by 40%. The spaced repetition techniques are a game-changer!"</p>
                <div className="homepage-testimonial-author">
                  <img src={require('../assets/review2.webp')} alt="Sarah M." className="homepage-testimonial-avatar" />
                  <div>
                    <h4>Sarah M.</h4>
                    <p>Medical Student</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="homepage-testimonial-card">
              <div className="homepage-testimonial-content">
                <p>"I was skeptical at first, but the results speak for themselves. My quiz scores have improved dramatically, and I'm finally able to recall information quickly during presentations. Highly recommend!"</p>
                <div className="homepage-testimonial-author">
                  <img src={require('../assets/review3.webp')} alt="Emily R." className="homepage-testimonial-avatar" />
                  <div>
                    <h4>Emily R.</h4>
                    <p>Graduate Student</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="homepage-stats-section">
          <div className="homepage-stats-grid">
            <div className="homepage-stat-card">
              <h3>50K+</h3>
              <p>Active Users</p>
            </div>
            <div className="homepage-stat-card">
              <h3>200+</h3>
              <p>Memory Exercises</p>
            </div>
            <div className="homepage-stat-card">
              <h3>89%</h3>
              <p>Success Rate</p>
            </div>
          </div>
        </section>

        <section className="homepage-cta-section">
          <div className="homepage-cta-content">
            <h2>Ready to Enhance Your Memory?</h2>
            <p>Join thousands of users who have already improved their cognitive abilities.</p>
            <button className="homepage-cta-button-secondary">Start Free Trial</button>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default HomePage;